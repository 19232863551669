<template lang="pug">
div(style="min-height: 70vh")
  v-card(outlined, min-height="420")
    v-card-title.d-flex.justify-content-center.mb-n4.ml-n2
      span.mr-1.font-weight-light.display-1 MY
      span.font-weight-black.display-1.head-text SHELF
    v-divider
    div.text-center.mx-auto(v-if="bookmarkId == 'reload'")
      v-row
        h5.font-weight-light.text-muted.mx-auto.mt-10.pa-7.pb-2 Please click the button this button to show your books in your shelf
      div.text-center.mx-auto.pa-10.pt-1
        v-btn(@click="showBooks()" color="primary") Show Books
    v-row.text-center.mx-auto(v-if="bookmarkId.length == 0")
      h4.font-weight-light.text-muted.mx-auto.mt-10 Shelf Empty
    v-row.text-center.mx-auto(v-else).pa-5
      v-col.mb-3(
        v-for="bookmark in concatenatedBooks",
        :key="bookmark.id",
        sm="4",
        lg="3",
        cols="6"
      )
        v-card.mx-auto(
          min-height="200px",
          width="180px",
          elevation="0",
          tile,
          @click="link(bookmark.id)"
        )
          bookCover(:url="bookmark.imageUrl" imgHeight="160" imgWidth="110").mx-auto
          v-card(height="20" color="primary" elevation=4).mt-1
        h6.font-weight-medium {{ bookmark.title }}
      v-col.text-center(v-if="dataReady==false && bookmarkId != 'reload'").pb-10
        v-progress-circular.mx-auto.mt-15.mb-15(
          :size="70"
          :width="7"
          color="primary"
          indeterminate)
    div.text-center.pa-10.pt-1(v-if="dataReady==true && bookmarkId.length > length && next_btn")
      v-btn(@click="more()" color="primary" ) More
    

</template>
<script>
import bookCover from "../components/BookCover.vue";
import { db } from "../main";

export default {
  components: { bookCover },
  data() {
    return {
      dialog: false,
      dataReady: false,
      length: 9,
      length1: 9,
      next_btn: true,
      prev_btn: false,
      query: [],
      concatenatedBooks: [],
      shelfList: [],
      bookmarks: [],
      bookmarkId: "reload",
    };
  },
  async created() {
    if (this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.lgAndUp) {
      this.length = 8;
      this.length1 = 8;
    }
    if (this.$store.state.userInfo.bookmarks) {
      this.showBooks();
    }
  },

  methods: {
    link: function(id) {
      this.$router.push(`./book-item/${id}`);
    },
    // async next() {
    //   this.query = this.bookmarkId.slice(this.length, this.bookmarkId.length);
    //   this.getBookmarks();
    //   this.nxt_btn = false;
    //   this.prev_btn = true;
    // },
    // async previous() {
    //   this.query = this.bookmarkId.slice(0, this.length);
    //   this.getBookmarks();
    //   this.nxt_btn = true;
    //   this.prev_btn = false;
    // },
    async getBookmarks() {
      (this.dataReady = false),
        await this.$bind(
          "bookmarks",
          db.collection("books").where("__name__", "in", this.query)
        ).then(() => {
          this.dataReady = true;
        });
    },
    async more() {
      if (this.bookmarkId.length >= this.length1 + this.length) {
        this.query = this.bookmarkId.slice(
          this.length,
          this.length1 + this.length
        );
        this.length = this.length1 + this.length;
        console.log(this.length + "more");
      } else {
        this.query = this.bookmarkId.slice(this.length, this.bookmarkId.length);
        console.log(this.length);
        this.next_btn = false;
      }
      await this.getBookmarks();
      this.concatenatedBooks.push.apply(this.concatenatedBooks, this.bookmarks);
      console.log(this.shelfList);
      console.log(this.concatenatedBooks);
    },
    showBooks() {
      this.bookmarkId = this.$store.state.userInfo.bookmarks;
      console.log(this.bookmarkId);
      if (this.bookmarkId.length < this.length) {
        this.query = this.bookmarkId.slice(0, this.bookmarkId.length);
      } else if (this.bookmarkId.length == this.length) {
        this.query = this.bookmarkId.slice(0, this.length);
      } else {
        this.query = this.bookmarkId.slice(0, this.length);
        this.nxt_btn = true;
      }
      this.getBookmarks();
      if (this.bookmarkId.length != 0) {
        this.getBookmarks();
        this.shelfList = this.bookmarks;
        this.concatenatedBooks = this.bookmarks;
      }
      console.log(this.query);
    },
  },
};
</script>
<style scoped>
.head-text {
  color: #010073;
}
</style>
